import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../layout"
import SEO from "../components/SEO"
import Textblock from "../components/Textblock"
import { DangerousHTML } from "../components/contentful/HTML"

interface DataType {
  watchingFaqs: Contentful.Cxn<Contentful.FAQ>
  accountFaqs: Contentful.Cxn<Contentful.FAQ>
}

const FaqPage = ({ data }: PageProps<DataType>) => (
  <Layout narrow>
    <Textblock>
      <SEO title="FAQ" />
      <h1>FAQ</h1>
      <ol>
        {data.watchingFaqs.edges.map((faq) => (
          <li>
            <div className="faq-card">
              <p>{faq.node.question}</p>
              <DangerousHTML>{faq.node.answer}</DangerousHTML>
            </div>
          </li>
        ))}
      </ol>
      <h2>Account Management</h2>
      <ol>
        {data.accountFaqs.edges.map((faq) => (
          <li>
            <div className="faq-card">
              <p>{faq.node.question}</p>
              <DangerousHTML>{faq.node.answer}</DangerousHTML>
            </div>
          </li>
        ))}
      </ol>
    </Textblock>
  </Layout>
)

export default FaqPage

export const pageQuery = graphql`
  query ($locale: String!) {
    watchingFaqs: allContentfulFaq(filter: { node_locale: { eq: $locale }, category: { eq: "Watching" } }) {
      edges {
        node {
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
          category
        }
      }
    }
    accountFaqs: allContentfulFaq(filter: { node_locale: { eq: $locale }, category: { eq: "Account" } }) {
      edges {
        node {
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
          category
        }
      }
    }
  }
`
